
import CommonStore from '@/store/modules/common';
import _ from 'lodash';
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {},
})
export default class extends Vue {
  firstRouterIndex = 0;
  get user(): any {
    return this.obtainAccount();
  }

  get firstRouter() {
    return this.$stores.retrieveStore(CommonStore).getFirstRouter
      ? this.$stores.retrieveStore(CommonStore).getFirstRouter
      : { path: '/noRouterView' };
  }

  created() {
    if (this.$stores.retrieveStatesStore().token) {
      this.$router.replace(this.firstRouter.path);
    }
  }

  loginSuccess(): void {
    this.$router.replace(this.firstRouter.path);
  }
}
